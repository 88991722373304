<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(t, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="t"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid" @click="showEditPopup(t)">
            <!-- preview -->
            <div class="ctl-review-container">
              <img alt="img" class="ctl-review-img" :src="t.img" />
            </div>
            <!-- locale name -->
            <!-- name -->
            <div class="ctl-item-param">
              {{ t.name }}
            </div>
            <!-- visibility -->
            <div
              class="ctl-item-param"
              :class="t.visibility ? 'visible' : 'hidden'"
            >
              {{ t.visibility ? "visible" : "hidden" }}
            </div>
            <!-- set -->
            <div class="ctl-item-param">
              {{ t.interaction && t.interaction.name }}
            </div>
            <!-- size -->
            <div class="ctl-item-param">
              {{ getSizeInMBs(t.size) }}
            </div>
            <!-- created at -->
            <div class="ctl-item-param">
              {{ getCorrectDate(t.created_at).slice(0, 19) }}
            </div>
            <!-- updated at -->
            <div class="ctl-item-param">
              {{ getCorrectDate(t.updated_at).slice(0, 19) }}
            </div>
            <!-- content manager -->
            <div class="ctl-item-param">
              {{ t.content_manager && t.content_manager.email }}
            </div>
            <!-- rating -->
            <div class="ctl-item-param">
              {{ t.rating || 0 }}
            </div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilCatalogFilters from "@/components/catalog/Filters";
import catalogMixin from "@/mixins/catalogMixin";
import "@/assets/css/catalog.css";

export default {
  name: "PagesCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    PilCatalogFilters
  },
  data() {
    return {
      // ? filters
      filters: {},
      sortParams: { created_at: "desc" },
      // ? titles
      titlesList: [
        { name: "preview" },
        { name: "name" },
        { name: "visibility" },
        { name: "pages" },
        { name: "size" },
        { name: "created at" },
        { name: "updated at" },
        { name: "content manager" },
        { name: "rating" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "catalog/pages"
    })
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(75px, 75fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.visible {
  color: #00ff00;
}
.hidden {
  color: red;
}
</style>
